@media(max-width: 1700px) {
  .wpo-site-header {
      padding: 0 30px;
  }

  .wpo-hero-section-1 .right-img2 {
      left: 38px;
  }
}


@media(max-width: 1500px) {
  .wpo-site-header #navbar>ul>li>a {
      padding: 30px 15px;
  }

  .wpo-site-header {
      padding: 0 10px;
  }

  .wpo-hero-section-1 .right-img2 {
      left: 18px;
  }

  .wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .r-img {
        right: 20px;
    }

}

@media(max-width: 1400px) {
  .wpo-site-header #navbar>ul>li>a {
      padding: 30px 10px;
      font-size: 18px;
  }


}

@media(max-width: 1200px) {

  .wpo-site-header #navbar>ul>li>a {
      padding: 30px 6px;
      font-size: 16px;
  }

  .wpo-site-header .header-right .theme-btn {
    font-size: 16px;
    padding: 11px 20px;
}

  .wpo-hero-section-1 .right-img2 {
      left: 0;
  }

  .wpo-hero-section-1.wpo-hero-section-2 .wpo-hero-title h2 {
        font-size: 45px;
        line-height: 50px;
    }

    .wpo-hero-section-1.wpo-hero-section-2 {
        height: 700px;
    }

    .wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .r-img {
        right: 20px;
        top: 35%;
    }
    .wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .wpo-happy-client {
        position: absolute;
        left: -85px;
        bottom: 35%;
    }

  .wpo-about-section .wpo-about-text h2 {
      font-size: 35px;
  }

  .wpo-service-section .wpo-service-item {
      padding: 30px;
  }

  .wpo-service-section .wpo-service-item .wpo-service-text h2 {
      font-size: 25px;
  }



  /* pricing */
  .wpo-pricing-section .wpo-pricing-items .wpo-pricing-item {
      padding: 30px 20px;
  }

  /* testimonial */
  .wpo-testimonial-section {
      padding-top: 20px;
      padding-bottom: 80px;
  }

  .wpo-blog-section .wpo-blog-content .wpo-blog-content-btm {
      padding: 20px 10px;
  }

  .wpo-site-footer .wpo-service-link-widget {
      padding-left: 30px;
  }

  .wpo-site-footer .about-widget ul li + li {
      margin-left: 15px;
  }
}

@media(max-width: 991px) {
  .wpo-hero-section-1 .right-img2 {
      left: -15px;
  }

  .wpo-hero-section-text .video-holder {
    position: absolute;
    left: 210px;
    bottom: -35px;
    top: auto;
    right: auto;
  }

  .wpo-hero-section-text .video-holder a {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }

  .wpo-hero-section-text .video-holder a .fi {
    font-size: 20px;
}

  .wpo-hero-section-text .video-holder a:before {
    left: -9px;
    top: -9px;
  }

  .right-img {
      display: none;
  }

  .wpo-hero-section-1 {
      height: 700px;
  }

  .wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img {
        padding-top: 20px;
        margin-top: 50px;
        border-top: 1px solid #ffff;
        padding: 20px;
    }

    .wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .wpo-happy-client {
        position: relative;
        left: 0;
        bottom: -30px;
        width: 450px;
        left: 0;
        margin: 0 auto;
    }

    .wpo-hero-section-1.wpo-hero-section-2 .right-vec .svg-shape{
        display: none;
    }

    .wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .video-holder {
        position: relative;
        left: -80px;
        top: 80px;
    }

    .wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .video-holder a {
        width: 60px;
        height: 60px;
        line-height: 65px;
    }

    .wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .r-img {
        left: 30%;
        top: -45px;
        right: auto;
    }

    .wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .video-holder a:before {
        left: -8px;
        top: -8px;
    }

    .wpo-hero-section-1.wpo-hero-section-2 .right-img{
        width: 100%;
    }

    .wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .video-holder {
        left: 60%;
        top: 0;
    }

  .wpo-features-section .wpo-features-item {
      margin-bottom: 30px;
  }

  .wpo-features-section {
      padding-bottom: 70px;
  }

  /*===================== 
about 
=================*/
  .wpo-about-section .wpo-about-wrap .wpo-about-img {
      margin: unset;
  }

  .wpo-about-section .wpo-about-text {
      margin: unset;
      margin-top: 50px;
  }

  .wpo-about-section {
      padding: 60px 0 120px;
  }

  /* pricing */
  .wpo-pricing-section .wpo-pricing-items .wpo-pricing-item {
      margin-bottom: 30px;
  }
  .wpo-pricing-section{
    padding-bottom: 90px;
  }

  .wpo-support-section .wpo-support-wrapper .wpo-support-text {
      margin-bottom: 30px;
  }

  .wpo-blog-section .wpo-blog-item{
    margin-bottom: 30px;
  }
  .wpo-blog-section{
    padding-bottom: 90px;
  }

  .wpo-blog-section {
      padding-bottom: 60px;
  }

  .wpo-site-footer .wpo-service-link-widget {
      padding-left: 0px;
  }



}


@media (max-width: 767px) {
    .wpo-hero-section-1 .wpo-hero-title .like_h2 {
        font-size: 40px;
        font-weight: 900;
        line-height: 60px;
        margin: 10px 0 25px;
        color: #232f4b;
        font-family: "Futura PT";
      }

      
  .header-right .theme-btn {
      padding: 20px 12px;
  }

  .wpo-hero-section-1 .wpo-hero-title h2 {
      font-size: 50px;
      line-height: 50px;
  }

  .wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .wpo-happy-client {
    width: 100%;
    padding: 18px 15px;
    display: block;
    text-align: center;
    bottom: -15px;
 }

.wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .wpo-happy-client .wpo-happy-client-slide {
    max-width: 216px;
    margin-left: 25px;
    margin: 0 auto;
    margin-top: 10px;
}

    .wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .r-img {
        left: 265px;
        top: -20%;
    }
    .wpo-hero-section-1.wpo-hero-section-2 {
        height: 750px;
    }

    .wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .video-holder {
        left: 20px;
        top: 6px;
    }

  .wpo-site-header .header-right .close-form {
      display: none;
  }


  .wpo-about-section {
      padding: 40px 0 80px;
  }

  .wpo-service-section {
      padding-bottom: 70px;
  }

  .wpo-testimonial-section .wpo-testimonial-title h2 {
      font-size: 32px;
      padding-left: 80px;
  }

  .wpo-testimonial-section .wpo-testimonial-title h2 i {
      width: 80px;
      height: 80px;
      line-height: 90px;
      font-size: 30px;
      left: 0;
      top: -65px;
  }
  .wpo-support-section .wpo-support-wrapper {
    padding: 40px 20px;
  }
  .wpo-support-section .wpo-support-wrapper .wpo-support-text h2 {
      font-size: 30px;
  }

  .wpo-hero-section-text .video-holder {
        left: 160px;
        bottom: -42px;
    }

    .wpo-hero-section-1.wpo-hero-section-2 .wpo-hero-title h2 {
        font-size: 36px;
        line-height: 50px;
    }
}


@media (max-width: 680px) {
    .wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .video-holder {
        left: -60px;
        top: 6px;
    }
}

@media (max-width: 575px) {

  .wpo-hero-section-1 {
      height: 400px;
  }

  .wpo-hero-section-1 .wpo-hero-title-top span {
      font-size: 18px;
  }

  .wpo-hero-section-1 .wpo-hero-title h2 {
      font-size: 30px;
      line-height: 35px;
  }

  .wpo-hero-section-1 .wpo-hero-subtitle p {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 30px;
  }
  .wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .video-holder {
        left: 0;
        top: 10px;
    }

    .wpo-hero-section-1.wpo-hero-section-2 .right-vec .right-img .r-img {
        left: 195px;
        top: -20%;
    }

    .wpo-hero-section-1.wpo-hero-section-2 {
        height: 400px;
        padding-top: 50px;
    }

  .wpo-gallery-section .wpo-section-title-button {
      text-align: center;
      margin-bottom: 40px;
  }
  .wpo-gallery-section .wpo-section-title {
      text-align: center;
  }

  .wpo-testimonial-section .wpo-testimonial-title h2 {
      font-size: 26px;
      padding-left: 80px;
  }
  .wpo-testimonial-section .wpo-testimonial-title h2 i {
      top: -38px;
  }

  .wpo-gallery-section {
      padding: 100px 0 60px;
  }
}