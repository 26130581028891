
body:has(.component__megamodal.active)
{
  overflow: hidden;
}


  @import "themify-icons";
  @import "flaticon";
  @import "bootstrap.min";
  @import "animate";
  @import "owl.carousel";
  @import "owl.theme";
  @import "slick";
  @import "slick-theme";
  @import "swiper.min";
  @import "owl.transitions";
  @import "jquery.fancybox";
  @import "odometer-theme-default";
  @import "component";
  @import "style";
  @import "responsive";
  @import "megamodal";

  @media (min-width: 768px) {
    
    @import "animate.min";
  }
 
  

